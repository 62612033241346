
















































































































































































import { mdiAccount } from '@mdi/js';
import _ from 'lodash';
import Vue from 'vue';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import LoginCard from '@/components/specific/LoginCard.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import { UIMemberFilterMapper } from '@/store/modules/ui/memberFilter';
import { UIPositionMapper } from '@/store/modules/ui/position';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type { UserAttributes } from '@/store/modules/domain/auth';
import type { Greeting } from '@/store/modules/ui/common';
import type { Group } from '@/store/modules/ui/group';
import type { Position } from '@/store/modules/ui/position';
import type { Workspace } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');
const GroupService = ServiceFactory.get('group');
const UserService = ServiceFactory.get('user');
const PositionService = ServiceFactory.get('position');
const WorkspaceService = ServiceFactory.get('workspace');

const onetimeSession = sessionStorage.getItem('oneTimeSession') as string;

export default Vue.extend({
  name: 'SignupInput',

  components: {
    LoginCard,
  },

  data(): {
    showedPassword: {
      confirm: boolean;
      new: boolean;
    };
    icons: {
      [key: string]: string;
    };
    signupParams: {
      password: string;
      confirmPassword: string;
      userName: string;
      kana: string;
      department: string;
      position: string;
      image?: string;
    };
    positions: string[];
    responseNewUserData: {
      workspaceId: string;
      email: string;
    };
    disabledUserName: boolean;
    disabledKana: boolean;
    previewUrl: string;
    imageRemoved: boolean;
    isSelecting: boolean;
    // 初期値設定のためanyを許容
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: any;
    dammyFlg: boolean;
  } {
    return {
      // 表示の順番で定義する
      /* eslint-disable vue/sort-keys */
      // パスワード表示切り替え
      showedPassword: {
        confirm: false,
        new: false,
      },
      icons: {
        mdiAccount,
      },
      signupParams: {
        password: '',
        confirmPassword: '',
        userName: '',
        kana: '',
        department: '',
        position: '',
      },
      positions: [],
      responseNewUserData: {
        workspaceId: '',
        email: '',
      },
      dammyFlg: false,
      disabledUserName: false,
      disabledKana: false,
      previewUrl: '',
      imageRemoved: false,
      isSelecting: false,
      file: '',
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIWorkspaceMapper.mapState(['workspace']),
    ...UICommonMapper.mapState(['showedGreetingPopup']),
    previewImage(): string {
      if (this.imageRemoved) {
        return '';
      }
      return this.previewUrl;
    },
  },
  created() {
    if (this.userAttributes.userName !== '') {
      this.signupParams.userName = this.userAttributes.userName;
      this.disabledUserName = true;
    }
    if (this.userAttributes.kana !== '') {
      this.signupParams.kana = this.userAttributes.kana;
      this.disabledKana = true;
    }
    this.signupParams.department =
      this.userAttributes.departments && this.userAttributes.departments.length > 0
        ? this.userAttributes.departments[0].departmentName
        : '';

    this.getList();

    this.initializeScreen();
  },
  methods: {
    ...DomainAuthMapper.mapActions(['setDomain', 'setToken', 'setUserAttributes']),
    ...UICommonMapper.mapActions([
      'clearEditGroupId',
      'clearGreeting',
      'clearMemberCircleUserId',
      'clearMessage',
      'clearPopupUserId',
      'clearProfileUserId',
      'hideAllPopup',
      'setNavigating',
      'setErrorMessage',
      'setGreeting',
      'showGreetingPopup',
      'showWebPushRecommendPopup',
    ]),
    ...UIWorkspaceMapper.mapActions(['setWorkspace']),
    ...UIGroupMapper.mapActions(['setGroupList']),
    ...UIPositionMapper.mapActions(['setPositionList']),
    ...UIMemberFilterMapper.mapActions(['clearFiltered']),

    async getList() {
      try {
        this.setNavigating({ navigating: true });

        // 役職一覧を取得
        const positionList = await PositionService.getPositionListOnetime(onetimeSession);

        // 役職情報をストアにセット
        this.setPositionList(positionList);
        const names: string[] = [];
        positionList.map((item: Position) => {
          names.push(item.name);
          return names;
        });
        this.positions = names;
      } catch (error) {
        this.$$log.error(error);
      }
      this.setNavigating({ navigating: false });
    },

    handleFileImport() {
      this.isSelecting = true;
      this.imageRemoved = false;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      const element = this.$refs.preview as HTMLInputElement;
      element.click();
    },

    initializeScreen() {
      this.clearEditGroupId();
      this.clearGreeting();
      this.clearMessage();
      this.clearPopupUserId();
      this.clearMemberCircleUserId();
      this.clearProfileUserId();
      this.clearFiltered();
      this.hideAllPopup();
    },

    removeImage() {
      const element = this.$refs.preview as HTMLInputElement;
      // リセット
      element.type = 'text';
      element.type = 'file';
      this.file = '';
      this.previewUrl = '';
      this.imageRemoved = true;
    },

    showPreview() {
      const element = this.$refs.preview as HTMLInputElement;
      if (this._.isNull(element.files)) {
        return;
      }

      // ファイルは一つ
      // eslint-disable-next-line prefer-destructuring
      this.file = element.files[0];
      this.previewUrl = URL.createObjectURL(this.file);
    },

    async signup() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.setNavigating({ navigating: true });

      try {
        const params = {
          image: '',
          kana: self._.trim(self.signupParams.kana),
          password: self._.trim(self.signupParams.password),
          position: self._.trim(self.signupParams.position),
          userName: self._.trim(self.signupParams.userName),
        };
        if (self.file) {
          const responseImage = await AuthService.uploadImageOnetime(
            onetimeSession,
            'user',
            self.file,
            {
              height: 90,
              width: 90,
            }
          );
          params.image = responseImage.url;
        }

        if (self.imageRemoved) {
          params.image = '';
        }

        const responseNewUser = await AuthService.updateUserOnetime(onetimeSession, params);
        this.responseNewUserData.workspaceId = responseNewUser.workspaceId;
        this.responseNewUserData.email = responseNewUser.email;

        // セッションIDを削除
        sessionStorage.removeItem('oneTimeSession');

        // 現在のドメインを保存（変更されたらログインへ強制移動のため）
        const urlObj = new URL(window.location.href);
        const domain = urlObj.hostname;
        self.setDomain({ domain });

        // ログイン処理
        self.login();
      } catch (error) {
        this.$$log.error(error);
      }
      self.setNavigating({ navigating: false });
    },
    // 実行順に定義
    /* eslint-disable-next-line vue/sort-keys */
    async login() {
      const self = this;
      // WebSocket用にトークンをCookieに保存する前に、既に保存されていた場合、削除（Webhookが届かない原因となりうるため。）
      if (self.$cookies.get('authToken')) {
        self.$cookies.remove('authToken');
      }

      self.$v.$touch();

      self.setNavigating({ navigating: true });

      try {
        const responseAuth = await AuthService.login({
          loginId: self.responseNewUserData.email,
          password: self.signupParams.password,
          workspaceId: self.responseNewUserData.workspaceId,
        });
        // トークンをストア
        self.setToken({
          token: _.get(responseAuth, 'token'),
          tokenType: _.get(responseAuth, 'tokenType'),
        });

        // WebSocket用にトークンをCookie保存
        self.$cookies.set('authToken', _.get(responseAuth, 'token'), {
          domain: settings.webSocket.cookieDomain,
        });

        // LocalStorageのloginDateを更新
        const now = new Date().getTime();
        localStorage.setItem('loginDate', JSON.stringify({ date: now }));

        // 必要情報をまとめて取得
        const userId = _.get(responseAuth, 'userId');
        const workspaceId = _.get(responseAuth, 'workspaceId');
        const procedures = [
          UserService.getUser(workspaceId, userId),
          WorkspaceService.getWorkspace(workspaceId),
          GroupService.getGroupList(workspaceId, userId),
        ];

        const [responseUser, responseWorkspace, responseGroup] = await Promise.all(procedures);

        // ユーザー情報をストアにセット
        const attr: UserAttributes = {
          // 型定義の順序で記述
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          agents: _.get(responseUser, 'agents'),
          adminLevel: _.get(responseUser, 'adminLevel'),
          email: _.get(responseUser, 'email'),
          google: _.get(responseUser, 'google.resourceId'),
          userName: _.get(responseUser, 'userName'),
          kana: _.get(responseUser, 'kana'),
          image: _.get(responseUser, 'image'),
          departments: _.get(responseUser, 'departments', []),
          position: _.get(responseUser, 'position'),
          projects: _.get(responseUser, 'projects', []),
          phoneNo: _.get(responseUser, 'phoneNo'),
          voiceXs: _.get(responseUser, 'voiceXs', []),
          timezone: _.get(responseUser, 'timezone', settings.defaultTimezone),
          status: _.get(responseUser, 'status'),
          emotion: _.get(responseUser, 'emotion'),
          place: _.get(responseUser, 'place'),
          device: _.get(responseUser, 'device'),
          currentApp: _.get(responseUser, 'currentApp'),
          chatUnread: _.get(responseUser, 'chatUnread'),
          regDate: _.get(responseUser, 'regDate'),
          updDate: _.get(responseUser, 'updDate'),
          webPushToken: _.get(responseUser, 'webPushToken'),
          webPushEnabled: _.get(responseUser, 'webPushEnabled'),
          /* eslint-enable vue/sort-keys */
        };
        self.setUserAttributes(attr);
        self.$$dayjs.tz.setDefault(attr.timezone);

        // ワークスペース情報をストアにセット
        const workspace: Workspace = {
          atdBorderTime: _.get(responseWorkspace, 'atdBorderTime'),
          atdLimitTime: _.get(responseWorkspace, 'atdLimitTime'),
          email: _.get(responseWorkspace, 'email'),
          image: _.get(responseWorkspace, 'image'),
          subDomain: _.get(responseWorkspace, 'subDomain'),
          tel: _.get(responseWorkspace, 'tel'),
          voiceXDID: _.get(responseWorkspace, 'voiceXDID'),
          voiceXTenant: _.get(responseWorkspace, 'voiceXTenant'),
          voiceXToken: _.get(responseWorkspace, 'voiceXToken'),
          workspaceId: _.get(responseWorkspace, 'workspaceId'),
          workspaceName: _.get(responseWorkspace, 'workspaceName'),
        };
        self.setWorkspace(workspace);

        // グループ一覧情報をストアにセット
        const groupList: Group[] = responseGroup as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });

        // グリーティング情報をストアにセット
        const greeting: Greeting = {
          imageUrls: ['@/assets/images/greeting/left@1x.svg'],
          texts: [
            'おはようございます！\n今週もあと2日ですね。\n今日も1日頑張りましょう！\nあいうえおかきくけこあいうえ',
          ],
        };
        self.setGreeting(greeting);
        self.showGreetingPopup();

        // Webプッシュ通知おすすめポップアップを表示する
        self.showWebPushRecommendPopup();

        self.$router.push('/signup/download');
      } catch (error) {
        self.$$log.error(error);
        self.setNavigating({ navigating: false });
      }
    },
  },
  validations() {
    return {
      signupParams: {
        // 表示順序で定義する
        /* eslint-disable vue/sort-keys */
        userName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        kana: {
          required,
          containsOnlyFullWidthKatakanaAndSpace: this.$$validators
            .containsOnlyFullWidthKatakanaAndSpace,
        },
        position: { maxLength: maxLength(32) },
        password: {
          required,
          passwordComplexity: this.$$validators.passwordComplexity,
          minLength: minLength(8),
          maxLength: maxLength(32),
        },
        confirmPassword: {
          required,
          sameAs: sameAs(() => this.signupParams.password),
        },
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
